<section>
    <img src="assets/img/projects-img/Pollo loco 1.png">
    <div class="blueprints-info">
        <div class="project-description">
            <h3>{{projectData.name}}</h3>
            <h4>{{projectData.languages}}</h4>
            <p>{{projectData.description | translate}}</p>
        </div>
        <div class="project-btns-container">
            <a href="{{projectData.livetestPath}}" target="_blank" class="test-btn">Live Test</a>
            <a href="{{projectData.githubPath}}" target="_blank" class="github-btn">Github</a>
        </div>
    </div>
</section>