<div class="content">
    <div class="info">
      <h1>Imprint</h1>
      <div class="imprint-infos"><p>Bekir Labjani<br />
        Fröhlichgasse 62<br />
        8010 Graz</p>
    </div>
    </div>
  
    <div class="contact">
      <h2>Contact</h2>
      <p>Telefon: +43660/3414583<br />
        E-Mail: bekir_labjani&#64;hotmail.com</p>
    </div>
  
    <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
  </div>