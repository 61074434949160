<img class="background-shadow-contact-two" src="./assets/img/background/contact-bgc.png">
<div class="purple-line"></div>
<section id="contact" class="fade-section">
<div class="headline">
    <p class="contact-headline">{{'ContactHeader' | translate}}</p>
</div>
    <img class="background-shadow-contact" src="./assets/img/background/contact-bgc.png">
    <div class="contact-container">
        <div>
            <div class="contact-left-container">
                <p class="contact-question">{{'ContactHeader' | translate}}</p>
                <p class="font-size-16">{{'ContactProblemHeader' | translate}}</p>
                <p class="font-size-16">{{'ContactDescription2' | translate}}<b>{{'ContactDescription3' | translate}}</b></p>
            </div>
        </div>
        <app-contact-form></app-contact-form>
    </div>
    <a href="#strPage"><img src="./assets/img/icons/circle-up-arrow.png"></a>
</section>