<div class="form-container">
  <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
    <div class="form-input-container">
      <div>
        <input
        #name="ngModel" 
        [class.is-valid]="name.valid && name.touched" 
        [class.is-invalid]="!name.valid && name.touched"
        class="form-input"
        placeholder="{{'ContactNamePlaceholder' | translate}}"
        name="name"
        id="name"
        [(ngModel)]="contactData.name"
        required
        />
        @if(!name.valid && name.touched){
        <span class="error-message">{{'ContactNameError' | translate}} </span>
        }
      </div>
      <div>
        <input
        #email="ngModel" 
        [class.is-valid]="email.valid && email.touched" 
        [class.is-invalid]="!email.valid && email.touched"
          class="form-input"
          placeholder="{{'ContactEmailPlaceholder' | translate}}"
          name="email"
          id="email"
          [(ngModel)]="contactData.email"
          required
        />
        @if(!email.valid && email.touched){
        <span class="error-message">{{'ContactEmailError' | translate}} </span>
        }
      </div>
      <div>
        <textarea
        #message="ngModel" 
        [class.is-valid]="message.valid && message.touched" 
        [class.is-invalid]="!message.valid && message.touched"
          placeholder="{{'ContactMessagePlaceholder' | translate}}"
          id="message"
          name="message"
          [(ngModel)]="contactData.message"
          minlength="5"
          required
        ></textarea>
        @if(!message.valid && message.touched && !message.disabled){
        <span class="error-message-textarea">{{'ContactMessageError' | translate}}</span>
        }
      </div>
    </div>
    <div class="user-interaction-container">
        <div>
            <div class="custom-checkbox">
                <input #checkbox="ngModel" [ngModelOptions]="{standalone: true}" type="checkbox" id="checkbox"
                    [(ngModel)]="isChecked">
                <label for="checkbox">{{'ContactPrivacyText1' | translate}}<a 
                  target="_blank" (click)="openPriv($event)" class='purple-text'>{{'ContactPrivacyLink' | translate}}</a>{{'ContactPrivacyText2' | translate}}</label>
            </div>
        </div>
        <div class="btn-container">
            <button type="submit" disabled [disabled]="!isChecked || contactForm.invalid">{{'ContactSubmitButton' | translate}}</button>
            <span id="msgSuccess" class="mail-success">{{'ContactSubmitButtonSucc' | translate}}</span>
        </div>
    </div>
  </form>
</div>
