<div class="content">
    <h1>Privacy Policy</h1>
    <h2>1. Data Protection at a Glance</h2>
    <h3>General Information</h3>
    <p>
      The following information provides a simple overview of what happens to your
      personal data when you visit this website. Personal data is any data with
      which you can be personally identified. For detailed information on the
      subject of data protection, please refer to our privacy policy listed below
      this text.
    </p>
    <h3>Data Collection on This Website</h3>
    <h4>Who is responsible for data collection on this website?</h4>
    <p>
      The data processing on this website is carried out by the website operator.
      You can find their contact details in the section "Notice on the Responsible
      Party" in this privacy policy.
    </p>
    <h4>How do we collect your data?</h4>
    <p>
      Your data is collected, on the one hand, by you providing it to us. This
      may, for example, be data that you enter into a contact form.
    </p>
  <p>
      Other data is collected automatically or after your consent when you visit
      the website through our IT systems. This is primarily technical data (e.g.,
      internet browser, operating system, or time of the page access). The
      collection of this data occurs automatically as soon as you enter this
      website.
    </p>
    <h4>What do we use your data for?</h4>
    <p>
      Part of the data is collected to ensure the error-free provision of the
      website. Other data may be used to analyze your user behavior.
    </p>
    <h4>What rights do you have regarding your data?</h4>
    <p>
      You have the right to receive information free of charge at any time about
      the origin, recipient, and purpose of your stored personal data. You also
      have the right to request the correction or deletion of this data. If you
      have given consent to data processing, you can revoke this consent at any
      time for the future. You also have the right, under certain circumstances,
      to request the restriction of the processing of your personal data.
      Furthermore, you have the right to lodge a complaint with the competent
      supervisory authority.
    </p>
    <p>
      You can contact us at any time regarding this and other questions on the
      subject of data protection.
    </p>
    <h3>Analysis Tools and Tools from Third-Party Providers</h3>
  <p>
      When visiting this website, your surfing behavior may be statistically
      evaluated. This is done primarily with so-called analysis programs.
    </p>
    <p>
      Detailed information on these analysis programs can be found in the
      following privacy policy.
    </p>
    <h2>2. Hosting</h2>
    <p>We host the content of our website with the following provider:</p>
    <h3>All-Inkl</h3>
    <p>
      The provider is ALL-INKL.COM - Neue Medien Münnich, Inh. René Münnich,
      Hauptstraße 68, 02742 Friedersdorf (hereinafter referred to as All-Inkl).
      For details, please refer to the privacy policy of All-Inkl:
      <a
        href="https://all-inkl.com/datenschutzinformationen/"
        target="_blank"
        rel="noopener noreferrer"
        >https://all-inkl.com/datenschutzinformationen/</a
      >.
    </p>
    <p>
      The use of All-Inkl is based on Art. 6(1)(f) DSGVO. We have a legitimate
      interest in a reliable presentation of our website. If corresponding consent
      has been requested, processing is exclusively based on Art. 6(1)(a) DSGVO
      and § 25 Abs. 1 TTDSG (Telecommunications Telemedia Data Protection Act).
    </p>
  </div>
  
