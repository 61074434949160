<section id="about-me" class="fade-section">
  <div class="about-me-container">
    <div class="me">
      <span>{{'AboutMeTitle' | translate}}</span>
      <p>
       {{'AboutMeIntro' | translate}}
      </p>
      @for (information of personalInformation; track $index) {
        <div class="infos">
          <img src="assets/img/icons/about-me-icons/{{information.path}}.png" alt="" />
          <p>
            {{information.text | translate}}
          </p>
        </div>
      }
    </div>
    <div class="img-from-me">
      <div class="green-circle">
        <img src="assets/img/myImages.png" alt="" />
      </div>
      <div class="green-line"></div>
    </div>
  </div>
</section>
