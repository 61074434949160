<img class="bgc-green" src="assets/img/background/Green shadow 2.png" alt="">
<section id="#skill" class="fade-section">
    <div class="mobile-skills-version dNone"> 
        <div class="my-skills-right">
        <div>
            <p class="my-skills-headline">{{'MySkillsTitle' | translate}}</p>
            <p class="my-skills-description">{{'SkillsDescription' | translate}}</p>
        </div>
        <div class="purple-line"></div>
    </div></div>
    <div class="skills">
        <div class="skills-flex-row">
            @for (icons of mySkills; track $index) {
                <div class="icon-box">
                    <img src="./assets/img/icons/my-skills-icon/{{icons.path}}.png">
                    <p>{{ icons.name }}</p>
                </div>
                }
                <div class="icons" (mouseover)="showHidden()" (click)="toggleHidden()">
                    <img class="hiddens" id="hidden-icon" src="assets/img/icons/my-skills-icon/icon-interest.png">
                    <div *ngIf="isHovered" class="hover-div">
                        <img src="assets/img/icons/my-skills-icon/img-learn.png" alt="Learn Image">
                        <img src="assets/img/icons/my-skills-icon/learn-icon.png" alt="Learn Icon">
                    </div>
                </div>
        </div>
    </div>
    <div class="contact-container">
        <div>
            <div class="my-skills-right">
                <div>
                    <p class="my-skills-headline">{{'MySkillsTitle' | translate}}</p>
                    <p class="my-skills-description">{{'SkillsDescription' | translate}}</p>
                    <span class="skill-headline-two">{{'LookingForSkill' |translate}} <span class="purpel-text"> {{'LookingForSkills' |translate}}</span>?</span><br>
                    <span class="my-skills-description colores">{{'ContactMeDescription' | translate}}</span>
                </div>
                <div class="purple-line"></div>
            </div>
        </div>
        <a class="button" href="#contact">{{'GetInTouchButton' | translate}}</a>
    </div>
    <div class="mobile-version-container">
        <span class="mobile-version-headline">{{'LookingForSkill' |translate}} <span class="purpel-text"> {{'LookingForSkills' |translate}}</span>?</span><br>
        <span class="my-skills-description colores">{{'ContactMeDescription' | translate}}</span>
        <a class="mobile-version-button" href="#contact">{{'GetInTouchButton' | translate}}</a>
    </div>

</section>