<section class="fade-section">
    <div class="main-container">
        <div class="my-images"><!-- Left Side -->
            <img class="img-one" src="assets/img/me/me-01.png" alt="">
            <img class="img-two" src="assets/img/background/29. photo shadows.png" alt="">
        </div>
        <div class="my-name"><!-- Right Side -->
            <div class="inside">
                <div class="headline-left-side">
                    <span>{{'Iam' | translate}}</span>
                </div>
                <div class="headline-right-side">
                    <span class="headline-name">Bekir Labjani</span>
                    <span class="activities">{{'Profession' | translate}}</span>
                </div>
            </div>
            <a href="#contact"><button>{{'LetsTalk' | translate}}</button></a>
        </div>
        <div class="buttom-links">
            <div class="desing-line"></div>
            <a href="https://github.com/BekirLabjani" target="_blank"><img src="assets/img/icons/github.png" alt=""></a>
            <a href="mailto:Bekir_labjani@hotmail.com" ><img src="assets/img/icons/mail.png" alt=""></a>
            <a href="https://www.linkedin.com/in/bekir-labjani-5203b1261/" target="_blank"><img src="assets/img/icons/lnkedin.png" alt=""></a>
            <a href="#contact" id="mail" class="my-email-adress">Bekir_labjani&#64;hotmail.com
            </a>
        </div><!--Bottom Links-->
    </div>
    <img class="curve-images" src="assets/img/background/28. Hero Background.png" alt="">
    <img class="curve-images-two" src="assets/img/background/51. bg VERSION B.png" alt="">
</section>