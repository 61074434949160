<section>
  <div class="logo-container">
<a href="#strPage" class="logo-link">
  <div class="name-logo">
    <span class="letter">B</span>
    <span class="letter">e</span>
    <span class="letter">k</span>
    <span>i</span>
    <span class="letter special">r</span>
  </div>
</a>
  </div>
  <button class="translate-button">
    <img (click)="switchLanguage('en')" [ngClass]="{'inactivate': isInactive}"  class="language-icon-en"
        src="./assets/img/icons/translate/en.jpeg">
    <img (click)="switchLanguage('de')" [ngClass]="{'inactivate': isInactive1}" class="language-icon-ger"
        src="./assets/img/icons/translate/ge.jpeg">
</button>
  <div class="menu-links">
    <!-- <div class="header-button">
      <a href="#about-me" class="my-links">About me</a>
      <div class="purpel-line-active"></div>
    </div>
    <div class="header-button">
      <a href="#skill" class="my-links">Skills</a>
      <div class="purpel-line-active"></div>
    </div>
    <div class="header-button">
      <a href="#portfolio" class="my-links">Portfolio</a>
      <div class="purpel-line-active"></div>
    </div> -->
    <div
      *ngFor="let menu of myContentLinks; let i = index"
      class="header-button"
      (click)="setActiveLink(i)"
    >
      <a [href]="menu.linkComponent" class="my-links">
        {{ menu.title | translate}}
      </a>
      <div *ngIf="activeLinkIndex === i" class="purpel-line-active"></div>
    </div>
  </div>
  <div class="burger-menu d-None">
    <img
      (click)="closeBurgerMenuOnClick()"
      src="assets/img/icons/about-me-icons/burzger-main-mobile.png"
      alt=""
    />
    <div class="mobile-screen-menu" [ngClass]="{ open: isMenuOpen }">
      <div>
        <a (click)="closeBurgerMenuOnClick()" href="#aboutMe">About me</a>
        <a (click)="closeBurgerMenuOnClick()" href="#skills">My skills</a>
        <a (click)="closeBurgerMenuOnClick()" href="#portfolio">Portfolio</a>
        <a (click)="closeBurgerMenuOnClick()" href="#contact">Contact</a>
      </div>
    </div>
  </div>
</section>
